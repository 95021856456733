<template>
  <div class="doors">
    <OpenDoor v-for="door in doors" :key="door.id" :door="door" />
  </div>
</template>

<script>
// @ is an alias to /src
import OpenDoor from "@/components/OpenDoor.vue";

export default {
  name: "DoorView",
  components: {
    OpenDoor,
  },
  data() {
    return {
      doors: [
        {
          id: 5928101,
          name: "P61",
          message: "Have a good stay and enjoy Valencia 😄",
          contact: "611 622 633",
        },
        {
          id: 5928102,
          name: "Just another door",
          message: "Have a good stay and enjoy Valencia 😄",
          contact: "611 622 633",
        },
        {
          id: 5928103,
          name: "This is not a door",
          message: "Have a good stay and enjoy Valencia 😄",
          contact: "611 622 633",
        },
      ],
    };
  },
};
</script>

<style scoped>
.doors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
