<template>
  <div
    :style="{
      'background-image': 'url(https://b.nbtag.com/lock.jpg)',
      'background-size': '100%',
    }"
  >
    <div v-if="flag == 1">
      <br />
      <br />
      <br />
      <span>You are @ {{ infos.dispname }}</span>
      <h4>{{ infos.dispmessage }}</h4>
      <br />
      <br />
      <h5>Please input your pin below and click Open</h5>
      <span>
        <a-input
          v-model:value="value"
          pattern="[0-9]*"
          type="text"
          placeholder="123456"
          style="width: 200px"
        />
      </span>
      <a-button
        type="primary"
        @click="sendcommand"
        :disabled="value === '' || disablebtn === 1"
        >Open</a-button
      >
      <br />
      <br />
      <h5>For assistance please contact:</h5>
      <h5>{{ infos.dispcontact }}</h5>
      <br />
      <warning-two-tone
        v-if="infos.conn_status != 'online'"
        two-tone-color="#eb2f96"
      />
      <check-circle-two-tone v-else two-tone-color="#52c41a" />
      {{ infos.conn_status }}
    </div>
    <div v-else-if="flag == 4" class="about">
      <br />
      <br />
      <h5>Lock with id # {{ props.wifimac }} Not Found.</h5>
      <h5>Please try again or contact tech support.</h5>
      <br />
      <br />
      <br />
      <br />
    </div>
    <div v-else class="about">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <a-spin tip="Loading...">
        <a-alert message="Connecting to the control unit"></a-alert>
      </a-spin>
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { useRoute } from "vue-router";
import { onBeforeMount, defineProps, ref } from "vue";
import { message } from "ant-design-vue";
import { WarningTwoTone, CheckCircleTwoTone } from "@ant-design/icons-vue";
const route = useRoute();
const value = ref("");
const flag = ref(0);
const infos = ref({});
const disablebtn = ref(0);
console.log(route.params.wifimac);
console.log(route.query.x);
onBeforeMount(async () => {
  document.title = "NBTAG";
  await axios
    .get("https://b.nbtag.com/api/pubcon/" + route.params.wifimac)
    .then((response) => {
      infos.value = response.data;
      console.log(infos.value);
      setTimeout(() => {
        flag.value = 1;
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
      setTimeout(() => {
        flag.value = 4;
      }, 1000);
    });
});

function sendcommand() {
  disablebtn.value = 1;
  return axios
    .get(
      "https://b.nbtag.com/api/pubcon/" +
        route.params.wifimac +
        "?x=" +
        value.value
    )
    .then((response) => {
      if (response.data == "goodkey") {
        message.success("Opening...", 5);
      } else {
        message.warn("Failed, check password please. ", 5);
      }
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setTimeout(() => {
        disablebtn.value = 0;
      }, 5000);
    });
}

const props = defineProps({
  wifimac: String,
});
</script>

<style scoped>
.icons-list :deep(.anticon) {
  margin-right: 6px;
  font-size: 24px;
}
</style>
