<template>
  <div class="open-door">
    <span>You are @ {{ door.name }}</span>
    <h4>{{ door.message }}</h4>
    <h5>For assistance please contact:</h5>
    <h5>{{ door.contact }}</h5>
    <span>
      <a-input
        v-model:value="value"
        pattern="[0-9]*"
        type="text"
        placeholder="Basic usage"
      />
    </span>
    <a-button type="dashed" @click="sendcommand">Open</a-button>
  </div>
  <div>
    {{ value }}
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";

export default {
  setup() {
    const value = ref("");
    return {
      value,
    };
  },
  name: "OpenDoor",
  props: {
    door: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sendcommand() {
      return axios
        .get("https://vue1.nbtag.com/ac0bfbdd8bfe")
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.open-door {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.open-door:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
</style>
